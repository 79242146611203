import logo from './logo.svg';
import './App.css';

// https://www.youtube.com/watch?v=WbV3zRgpw_E&t=6960s
import React, { useState }  from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { HashRouter  as Router, Routes, Route } from 'react-router-dom';

// Screen
import Navbar from './components/Navbar/Navbar'
import HomeScreen from './components/home/HomeScreen';
import PublicationScreen from './components/publication/PublicationScreen';
import ContactScreen from './components/contact/ContactScreen';
import ProjectScreen from './components/projects/ProjectScreen';
import AboutMeScreen from './components/AboutMe/AboutMeScreen';
import AboutUsScreen from './components/AboutUs/AboutUsScreen';
import ResumeScreen from './components/Resume/ResumeScreen';
import Footer from './components/Footer/Footer';

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       The Gifty-Peter Foundation
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <Router basename="/">
    <Navbar/>
      <div className="container">
        <Routes>
            <Route path="/" element={<HomeScreen/>} />
            <Route path="/projects" element={<ProjectScreen/>} />
            <Route path="/aboutus" element={<AboutUsScreen/>} />
            <Route path="/contact" element={<ContactScreen/>} />
            {/* <Route path="/publications" element={<PublicationScreen/>} /> */}
            {/* <Route path="/aboutme" element={<AboutMeScreen/>} /> */}
            {/* <Route path="/resume" element={<ResumeScreen/>} /> */}
        </Routes>
        <Footer/>
      </div>
  </Router>
  );
}

export default App;
