// https://www.youtube.com/watch?v=WbV3zRgpw_E&t=6960s
import React, { useState }  from 'react'
// import "./AboutUs.css"
import Title from '../Title/Title'
import AboutUs1 from './AboutUs1'
import AboutUs2 from './AboutUs2'
import AboutUs3 from './AboutUs3'
import AboutUs4 from './AboutUs4'
import AboutUs5 from './AboutUs5'


const AboutUsScreen = () => {
  const [playState, setPlayState] = useState(false)
  return (
    <div>

      <div className="container">
        <Title subTitle="" title="Team Members"/>
        {/* <Title subTitle="Team" title=""/> */}
        <AboutUs1/>
        <Title title="COLLABORATIONS AND SUPPORTS"/>
        <AboutUs2/>
        {/* <Title subTitle="Co-supervised Masters' Thesis" title=""/> */}
        {/* <AboutUs3/> */}
        {/* <Title subTitle="Other Research I Contributed to" title=""/> */}
        {/* <AboutUs4/> */}
        {/* <AboutUs5/> */}
        {/* <Title subTitle="Scientific" title=""/> */}
      </div>
    </div>
  )
}

export default AboutUsScreen