


import React, { useState } from 'react';
import './Home3.css';
import Title from '../Title/Title';
import white_arrow from '../../assets/gallery/white-arrow.png';
import gallery_1 from '../../assets/gallery/gallery-1.png';
import gallery_2 from '../../assets/gallery/gallery-2.png';
import gallery_3 from '../../assets/gallery/gallery-3.png';
import gallery_4 from '../../assets/gallery/gallery-4.png';

import gallery_01 from '../../assets/gallery/gallery-01.jpg';
import gallery_02 from '../../assets/gallery/gallery-02.jpg';
import gallery_03 from '../../assets/gallery/gallery-03.jpg';
import gallery_04 from '../../assets/gallery/gallery-04.jpg';
// import gallery_04 from '../../assets/gallery/gallery-05.jpg';
// import gallery_06 from '../../assets/gallery/gallery-06.jpg';

import gallery_11 from '../../assets/gallery/gallery-11.jpg';
import gallery_12 from '../../assets/gallery/gallery-12.jpg';
import gallery_13 from '../../assets/gallery/gallery-13.jpg';
import gallery_14 from '../../assets/gallery/gallery-14.jpg';

import gallery_21 from '../../assets/gallery/gallery-21.jpg';
import gallery_22 from '../../assets/gallery/gallery-22.jpg';
import gallery_23 from '../../assets/gallery/gallery-23.jpg';
import gallery_24 from '../../assets/gallery/gallery-24.jpg';


const Home3 = () => {
  const [seeMore, setSeeMore] = useState(false);
  const buttonTitle = seeMore ? "Show less pictures" : "Show more pictures";

  return (
    <div className=''>
      {/* <div className=""> */}
        <Title title={"Events & Gallery"}/>
      {/* </div> */}
      <div className="gallery">
        <figure>
          <img src={gallery_01} alt="Description for Image 1" />
          <figcaption>Provides computers and computer services</figcaption>
        </figure>
        <figure>
          <img src={gallery_02} alt="Description for Image 2" />
          <figcaption>Provides books and other souvenirs</figcaption>
        </figure>
        <figure>
          <img src={gallery_03} alt="Description for Image 3" />
          <figcaption>Provides books and other souvenirs</figcaption>
        </figure>
        <figure>
          <img src={gallery_04} alt="Description for Image 4" />
          <figcaption>Excited kids</figcaption>
        </figure>
      </div>

      {seeMore && (
        <>
          <div className="gallery">
            <figure>
              <img src={gallery_11} alt="Description for Image 5" />
              <figcaption>Provides computers and computer services</figcaption>
            </figure>
            <figure>
              <img src={gallery_12} alt="Description for Image 6" />
              <figcaption>Classroom experience</figcaption>
            </figure>
            <figure>
              <img src={gallery_13} alt="Description for Image 7" />
              <figcaption>Traditional way of studying</figcaption>
            </figure>
            <figure>
              <img src={gallery_14} alt="Description for Image 8" />
              <figcaption>Provides books and other souvenirs</figcaption>
            </figure>
          </div>
          <div className="gallery">
            <figure>
              <img src={gallery_21} alt="Description for Image 9" />
              <figcaption>Excited students in the classroom</figcaption>
            </figure>
            <figure>
              <img src={gallery_22} alt="Description for Image 10" />
              <figcaption>Excited students in the classroom</figcaption>
            </figure>
            <figure>
              <img src={gallery_23} alt="Description for Image 11" />
              <figcaption>Encouraging the young students</figcaption>
            </figure>
            <figure>
              <img src={gallery_24} alt="Description for Image 12" />
              <figcaption>Sports events </figcaption>
            </figure>
          </div>
        </>
      )}

      <button onClick={() => { setSeeMore(!seeMore) }} className='btn dark-btn more'>
        {buttonTitle}
        {/* <img src={white_arrow} alt="" /> */}
      </button>
    </div>
  );
};

export default Home3;
