import React from 'react'
import "./Home1.css"
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
// import img_1 from "../../assets/img/hero_img.png"
import img_1 from "../../assets/logo/logo-3.png"
import image_1_students from "../../assets/home/image-1-students.jpg"
import image_2_students from "../../assets/home/image-2-students.jpg"
import Title from '../Title/Title';


const Home1 = () => {
    return (
      <div className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <p className="section--title">Aim / Vision</p>

            <p className="hero--section-description">
            Our primary aim is to implement programs that will help students develop their reading habits, computer skills, and overall academic prowess. 
            
              <br /> By equipping students with these essential skills, we hope to not only improve their academic performance but also inspire a lifelong love for learning and self-improvement. <br />
            </p>
          </div>

          <div className="btn-container">
            <Link to="/aboutus">
              <button className="btn  dark-btn">About us</button>
            </Link>
            <Link to="/projects">
              <button className="btn  dark-btn">Projects</button>
            </Link>
            <Link to="/contact">
              <button className="btn  dark-btn">Contact us</button>
            </Link>
          </div>

        </div>
        <div className="hero--section--img">
          <img src={image_1_students} alt="Hero Section" />
        </div>
      </div>
    );
  }

export default Home1