import React, { useState } from 'react';
import "./Home2.css"
import dark_arrow from "../../assets/logo/dark-arrow.png"
import Gallery from '../Gallery/Gallery'



const Home2 = ({ onButtonClick, galleryTitle="Gallery" }) => {

    const [readMore, setReadMore] = useState(false)

  return (
    <div className='hero container'>
        <div className="hero-text">
          {/* <h2>Co-founder</h2> */}
          <h1>Giving back to the community</h1>
          <p className='eduzolve-mission'>
            The Gifty-Peter Foundation offers scholarship programs to recognize and reward top-performing students at Weta Community Basic Schools. 
            <br /> <br />
            {readMore && (
                <p className='eduzolve-mission'>
                In addition, we provide the school with computers and computational services, including establishing a computer lab and offering training. 
                <br /> <br />
                We also supply a variety of books and educational materials to encourage and support the development of reading habits ......
                </p>
            )}
          </p>
          {/* <a href="https://www.uef.fi/en/article/from-west-africa-to-finland-for-higher-education-and-entrepreneurship-peter-and-oluseguns" target='_blank'>
            <button className='btn'>Read more<img src={dark_arrow} alt="" /> </button>
          </a> */}
          <button onClick={() => {setReadMore(!readMore)}} className='btn more-gallery'> {readMore ? "Show less": "Read more"}  {readMore ? null : <img src={dark_arrow} alt="" /> } </button>
          {/* <button onClick={onButtonClick} className='btn more-gallery'>{galleryTitle}</button> */}
          {/* <Gallery/> */}
        </div>
    </div>
  )
}

export default Home2


