// import data from "../../data/index.json";
import data from "../../assets/data/index.json"
import "./Projects2.css"
import img_1 from "../../assets/projects/placeholder-image-1.png"

const Projects3 = () => {
  return (
    <div className="portfolio--section">





      <div className="portfolio--section--container">


          <div className="portfolio--section--card">
            <div className="portfolio--section--card--content">
              <div>
                <h3 className="portfolio--section--title">Books and Other Educational Materials</h3>
                <p>
                To support the development of reading habits, we provide a range of books and educational materials. 
                This will include fiction and non-fiction books, reference materials, and educational games. 
              </p>
              </div>
              <a href="#">
                <p className="text-sm portfolio--link">
                Support this project
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 19" fill="none">
                      <path d="M4.66667 1.66675H18V15.0001M18 1.66675L2 17.6667L18 1.66675Z" stroke="currentColor" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </p>
              </a>
            </div>
          </div>


          <div className="portfolio--section--card">
            <div className="portfolio--section--card--content">
              <div>
                <h3 className="portfolio--section--title">Building a Library</h3>
                <p> 
                We are working towards building a library that will serve as a hub for learning, literacy, and growth. 
                This library will provide access to a wide range of books, resources, and educational programs, 
                empowering individuals of all ages to broaden their knowledge and create opportunities for a brighter future..
                </p>
              </div>
              <a href="#">
                <p className="text-sm portfolio--link">
                Support this project
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 19" fill="none">
                      <path d="M4.66667 1.66675H18V15.0001M18 1.66675L2 17.6667L18 1.66675Z" stroke="currentColor" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </p>
              </a>
            </div>
          </div>


      </div>

    </div>
  );
}

export default Projects3
